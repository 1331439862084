import React, { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import SignatureCanvas from 'react-signature-canvas';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { Question } from '../../../../Types';
import Button from '@material-ui/core/Button';
import { Grid, Box, Typography } from '@material-ui/core';
import { sendFileToServiceOS } from '../../../../Providers/backend';
import Cookies from 'js-cookie';
import { PopupAlert } from '../../../../Hooks';

// Set workerSrc to use the latest version of pdf.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface MyPDFComponentProps {
    name: Question[];
    file: string;
    onStepChange: (pageNum: React.SetStateAction<number>) => void;
    step: number;
}

const MyPDFComponent: React.FC<MyPDFComponentProps> = ({ name, file, onStepChange, step }) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [error, setError] = useState<string | null>(null);
    const signatureCanvasRef = useRef<SignatureCanvas>(null);

    const todayDate = new Date().toLocaleDateString('en-GB');

    const onLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
        setError(null);
    };

    const onLoadError = (error: any) => {
        console.error('Error loading PDF:', error);
        setError('Error loading PDF.');
    };

    const goToNextPage = () => {
        setPageNumber((prev) => Math.min(prev + 1, numPages ?? 1));
    };

    const goToPrevPage = () => {
        setPageNumber((prev) => Math.max(prev - 1, 1));
    };

    const clearSignature = () => {
        signatureCanvasRef.current?.clear();
    };

    const saveSignature = async () => {
        if (!signatureCanvasRef.current || signatureCanvasRef.current.isEmpty()) {
            PopupAlert("The document must be signed.", "error");
            return;
        }

        const signatureData = signatureCanvasRef.current.toDataURL();
        const patientName = `${name[1].answer.answerValue} ${name[2].answer.answerValue}`;

        const pdfBytes = await fetch(`/pdf/${file}.pdf`).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(pdfBytes);
        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const image = await pdfDoc.embedPng(signatureData);
        const pages = pdfDoc.getPages();
        const lastPage = pages[pages.length - 1];
        const { width, height } = lastPage.getSize();

        lastPage.drawText(patientName, {
            x: width - 250,
            y: height / 3 + 30,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });

        lastPage.drawImage(image, {
            x: width - 200,
            y: height / 4 - 10,
            width: 200,
            height: 100,
        });

        lastPage.drawText(`${todayDate}`, {
            x: width - 250,
            y: height / 4 - 30,
            size: 12,
            font: helveticaFont,
            color: rgb(0, 0, 0),
        });

        const pdfBytesWithSignature = await pdfDoc.save();
        await sendFileToServiceOS(
            Buffer.from(pdfBytesWithSignature).toString('base64'),
            file,
            "",
            Cookies.get("sid") + ""
        ).then(() => {
            onStepChange(step + 1);
        });
    };


    return (
        <Box textAlign="center" p={3}>
            {error && <Typography color="error">{error}</Typography>}
            <Box my={2}>
                <Button
                    onClick={goToPrevPage}
                    disabled={pageNumber === 1}
                    style={{ marginRight: 10, backgroundColor: 'white' }}
                >
                    Previous
                </Button>
                <Button
                    onClick={goToNextPage}
                    disabled={pageNumber === numPages}
                    variant="contained"
                    color="primary"
                >
                    Next
                </Button>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <Document
                    file={`/pdf/${file}.pdf`}
                    onLoadSuccess={onLoadSuccess}
                    onLoadError={onLoadError}
                    loading="Loading PDF..."
                >
                    <Page pageNumber={pageNumber} />
                </Document>
            </Box>

            <Typography variant="body1">
                Page {pageNumber} of {numPages}
            </Typography>

            <Box mt={4}>
                <Typography variant="h6">Sign Below</Typography>
                <SignatureCanvas
                    ref={signatureCanvasRef}
                    penColor="black"
                    canvasProps={{
                        width: 500,
                        height: 200,
                        className: 'signature-canvas',
                        style: { border: '1px solid black' },
                    }}
                />
            </Box>

            <Grid container spacing={2} justifyContent="center" style={{ marginTop: 20 }}>
                <Grid item>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={clearSignature}
                    >
                        Clear
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={pageNumber !== numPages}
                        onClick={() => {
                            saveSignature();
                        }}
                    >
                        Sign
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MyPDFComponent;
