import {
    Page,
    Text,
    View,
    Document,
    Image,
    StyleSheet,
    Font,
    pdf,
    Canvas,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
    page: {
        fontFamily: "Maven",
        margin: 5,
        fontSize: 24,
        color: "white",
        backgroundColor: "black",
        textAlign: "center"
    },
    radioButton: {
        position: 'relative',
        width: 10,
        height: 10,
        borderRadius: 10,
        borderWidth: 2,
        borderColor: '#818589',
        marginRight: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    checkedRadio: {
        backgroundColor: '#8d2d42', // Change color to represent the checked state
        padding: 1
    },
    inline: {
        margin: 2,
        fontFamily: "MontserratRegular",
        fontSize: 10
    },
    text: {
        fontFamily: "MontserratRegular",
        fontSize: 10,
        marginBottom: 3,
        flexWrap: 'wrap',
        width: "75%"

    },
    text2: {
        fontFamily: "MontserratRegular",
        fontSize: 12,
        color: "#8d2d42",

    },
    text3: {
        fontFamily: "MontserratRegular",
        fontSize: 9,
        color: "#8d2d42",

    },
    answerLeft: {
        fontFamily: "MontserratRegular",
        fontSize: 9,
        color: "#8d2d46",
        textAlign: "right",
    },
    answerRight: {
        fontFamily: "MontserratRegular",
        fontSize: 9,
        color: "#8d2d46",
        textAlign: "right",
    },
    titles: {
        margin: 3,
        textDecoration: "underline", textDecorationColor: "#8d2d46",
        fontFamily: "MontserratRegular",
        fontSize: 14,
        textAlign: "left",
    },
    confirms: {
        fontFamily: "MontserratRegular",
        fontSize: 11,
        textAlign: "justify",
        margin: 10,

    },
    radioButtonGroupLeft: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    radioButtonGroupRight: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    justifyContentText: {
        justifyContent: 'space-between',
    },
})

const isYesAnswer = (answerValue: string) => ["Yes", "Ja", "да", "Да", "oui", "Sí", "Sim", "Da", "si", "Kyllä"].includes(answerValue);
const isNoAnswer = (answerValue: string) => ["No", "Nein", "не", "Нет", "Non", "Não", "Nu", "no", "Nej", "Nee", "Nei", "Ei"].includes(answerValue);

export const renderQuestionsRight = (questions: any, page: number, getFieldProps: any) => {
    return questions.map((item: any, index: any) => {
        const answerValue = getFieldProps(item.questionID).value.answerValue;

        // Check if the current question should be rendered for the given page
        if (item.page !== page) return null;

        return (
            <View style={{ flexDirection: "column" }} key={index}>
                {item.answer.answerType === "yes_no" ? (
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={styles.text}>{item.questionText} </Text>
                        <View style={styles.radioButtonGroupRight}>
                            <View style={isYesAnswer(answerValue) ? [styles.radioButton, styles.checkedRadio] : styles.radioButton} />
                            <Text style={styles.text3}>Yes</Text>
                            <Text>{"  "}</Text>
                            <View style={isNoAnswer(answerValue) ? [styles.radioButton, styles.checkedRadio] : styles.radioButton} />
                            <Text style={styles.text3}>No</Text>
                        </View>
                    </View>
                ) : (
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={styles.text}>{item.questionText} </Text>
                        <View style={[styles.radioButtonGroupRight, { backgroundColor: "#F1F2F2", width: "65%" }]}>
                            <Text style={styles.answerLeft}>{answerValue}</Text>
                        </View>
                    </View>
                )}
            </View>
        );
    });
};
export const renderQuestionsLeft = (questions: any, page: number, getFieldProps: any) => {
    return questions.map((item: any, index: any) => {
        const answerValue = getFieldProps(item.questionID).value.answerValue;

        // Check if the current question should be rendered for the given page
        if (item.page !== page) return null;

        return (
            <View style={{ flexDirection: "column" }} key={index}>
                {item.answer.answerType === "yes_no" ? (
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={styles.text}>{item.questionText} </Text>
                        <View style={styles.radioButtonGroupLeft}>
                            <View style={isYesAnswer(answerValue) ? [styles.radioButton, styles.checkedRadio] : styles.radioButton} />
                            <Text style={styles.text3}>Yes</Text>
                            <Text>{"  "}</Text>
                            <View style={isNoAnswer(answerValue) ? [styles.radioButton, styles.checkedRadio] : styles.radioButton} />
                            <Text style={styles.text3}>No</Text>
                        </View>
                    </View>
                ) : (
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={styles.text}>{item.questionText} </Text>
                        <View style={[styles.radioButtonGroupLeft, { backgroundColor: "#F1F2F2", width: "65%" }]}>
                            <Text style={styles.answerLeft}>{answerValue}</Text>
                        </View>
                    </View>
                )}
            </View>
        );
    });
};